
export default {
  data() {
    return {
      menuActive: "",
    };
  },
  mounted() {
    this.menuActive = this.$route.name;
  },
  computed: {
    isActivePage() {
      return (page) => {
        //console.log("isActivePage page",page)
        const currentPath = this.$route.path;
        //console.log("isActivePage currentPath",currentPath)
        return page=== currentPath || currentPath === `/${page}` || currentPath.startsWith(`/${page}/`);
      };
    }
  }
};
