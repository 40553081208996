
import darkTheme from "../plugins/dark";
import defaultTheme from "../plugins/default";

export default {
  head() {
    return {
      htmlAttrs: {
        class: [
          this.$dir() === "rtl" ? "rtl" : "ltr",
          this.$colorMode.value === "dark" ? "dark-mode" : "light-mode",
        ],
      },
    };
  },
  data() {
    return {
      homeItems: [],
      news: [],
      chart: null,
      toggleMenuFlag: "",
      show: true,
      price: null,
      mouseActiveIndex: false,
      symbolTitle: "",
    };
  },
  watch: {
    "$colorMode.preference"(newValue, oldValue) {
      if (newValue === "dark") {
        this.$highChart.setOptions(darkTheme);
      } else {
        this.$highChart.setOptions(defaultTheme);
      }
      if (this.$refs.container) {
        this.chart = this.$highChart.stockChart("container", this.chartOptions);
      }
    },
  },
  computed: {
    symbols() {
      return this.homeItems.map((i) => i.symbol).join(",");
    },
  },
  async created() {
    if (process.client && !this.$cookies.get("quex-token")) {
      // await this.$axios.get("api-token").then((response) => {
      //   if (response.data) {
      //     this.$cookies.set("quex-token", response.data);
      //   }
      // });
    }
  },
  mounted() {
    /*if (process.client) {
      let self = this;

      // Create WebSocket connection.
      const socket = new WebSocket(
        "wss://ws.twelvedata.com/v1/quotes/price?apikey=" +
          this.$config.TWELVE_DATA_API_KEY
      );

      // Connection opened
      socket.addEventListener("open", function (event) {
        socket.send(
          JSON.stringify({
            action: "subscribe",
            params: {
              symbols: self.symbols,
            },
          })
        );
      });

      // Listen for messages
      socket.addEventListener("message", function (event) {
        let data = JSON.parse(event.data);

        if (data.event === "price") {
          self.updatePrice(data);
        }
      });
    }*/

    /*const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6197390711712332"
    );
    script.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(script);*/
  },
  methods: {},
};
