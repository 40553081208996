
export default {
  data() {
    return {
      tokenExpire: "",
    };
  },
  methods: {
    getTokenExpire() {
      this.tokenExpire = JSON.parse(localStorage.getItem("auth._token_expiration.local"));
      var expiretime = new Date(this.tokenExpire);
      var date = expiretime.getDate();
      var month = expiretime.toLocaleString("default", { month: "short" });
      var year = expiretime.getFullYear();
      var hours = expiretime.getHours();
      var minutes = expiretime.getMinutes();
      var seconds = expiretime.getSeconds();
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (month < 10) {
        month = "0" + month;
      }
      var remainingtime =
        date + "." + month + "." + year + " " + hours + ":" + minutes + ":" + seconds;
      this.tokenExpire = remainingtime;
    },
    AddMinutesToDate(ev) {
      this.addMinutesToDate();
    },
    addMinutesToDate() {
      var expiretoken = JSON.parse(
        window.localStorage.getItem("auth._token_expiration.local")
      );
      var expiretime = new Date(expiretoken);
      var finalexpiretime = new Date(expiretime.getTime() + 2 * 60000);
      this.$auth.strategies.local.options.token.maxAge =
        this.$auth.strategies.local.options.token.maxAge + 2;
      window.localStorage.setItem(
        "auth._token_expiration.local",
        JSON.stringify(finalexpiretime.getTime())
      );
      this.$cookies.set(
        "auth._token_expiration.local",
        JSON.stringify(finalexpiretime.getTime())
      );
      var expiretime = JSON.parse(
        window.localStorage.getItem("auth._token_expiration.local")
      );
      var expirecookie = JSON.parse(this.$cookies.get("auth._token_expiration.local"));
      this.getTokenExpire();
    },
  },
  mounted() {
    this.getTokenExpire();
    document.addEventListener("click", this.AddMinutesToDate);
  },
  props: ["stockOne", "stockTwo"],
};
