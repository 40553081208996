
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import {VueAutosuggest} from "vue-autosuggest";

import algoliasearch from 'algoliasearch';
import Vue from 'vue';
import Typesense from 'typesense';
import VueInstantSearch from 'vue-instantsearch';
//const algoliaAppId = process.env.ALGOLIA_APPLICATION_ID;
//const algoliaApiKey = process.env.ALGOLIA_API_KEY;

//algolia
const algoliaAppId = "AMC0H1C577";
const algoliaApiKey = "b36a74c0c7eeae1c6f4423a36e527b24";
//console.log("algoliaAppId : ",algoliaAppId);
//console.log("algoliaApiKey : ",algoliaApiKey);
const client = algoliasearch(algoliaAppId, algoliaApiKey);
const index = client.initIndex('securedz_stat_index');

//console.log("typesenseClient : ",process.env.NUXT_TYPESENSE_API_KEY);
//typesense
const typesenseClient = new Typesense.Client({
  nodes: [
    {
      //protocol: 'http', // or 'https' if using SSL
      //host: '65.109.8.121',
      port: '8108',

      protocol: 'https', // or 'https' if using SSL
      host: 'quex.net',
    },
  ],
  //apiKey: 'WylcECnuurQXref5CBv0QjJX1ICb0wDvoPeccMfNIp4nB4pP',
  apiKey: process.env.NUXT_TYPESENSE_API_KEY,
});

Vue.use(VueInstantSearch);

export default {
  components: {
    VueTypeaheadBootstrap,
    VueAutosuggest,
  },
  data() {
    return {
      isHide: false,
      query: "",
      items: [],
      isFullscreen: false,
      toggleMenuFlag: "",
      userData: [],
      countryLanguage: "",
      search: null,
      searchClient: typesenseClient,
      exchange: 'XSTU',
      isLangSelect:false,
      typeColor : {
        'Stock': '#546e7a',
        'Commodity': '#8c6d62',
        'Currency': '#3849aa',
        'Index': '#28b5f4',
      }
    };
  },
  watch: {
    query(newQuery) {
      if (newQuery !== "") {
        this.searchQuery();
      }
    },
    items: function (newVal, preVal) {
      for (let index = 0; index < newVal.length; index++) {
        const element = newVal[index];
        if (element.sources === 1) {
          //this.getCertoxdataPrice(element);
          //this.realTimeCertoxData(element);
        }
        if (element.sources === 2) {
          //this.getTwelvedataPrice(element);
          //this.realTimeTwelvedata(element);
        }
      }
    },
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
  },
  methods: {
     async searchWithCache(query) {
      const cacheKey = `typesense_search_${query}`;
      const cachedResults = JSON.parse(localStorage.getItem(cacheKey));

      if (cachedResults) {
        return cachedResults;
      } else {

        //query_by: 'name,isin,symbol,wkn',
        const searchParams = {
          q: query,
          query_by: 'underlyingtyp,name,isin,symbol,wkn',
        };

        const searchParams2 = {
          q: query,
          query_by: 'underlyingtyp,typ,name,isin,symbol,wkn',
        };

        //multiple
        //const collections = ['underlyings','securedz_stat'];
        const collections = ['underlyings'];
        const results = await Promise.all(collections.map((collection,i) => {
          return this.searchClient
              .collections(collection)
              .documents()
              .search(i===0 ? searchParams : searchParams2);
        }));

        //const hits = results.flatMap(result => result.hits);
        const hits = results.flatMap(result => result.hits)
            .reduce((uniqueHits, currentHit) => {
              if (!uniqueHits.some(hit => hit.document.isin === currentHit.document.isin)) {
                uniqueHits.push(currentHit);
              }
              return uniqueHits;
            }, []);
        //multiple


        // Store the search results in local storage with the cache key
        localStorage.setItem(cacheKey, JSON.stringify(hits));

        return hits;
      }
    },
    async searchQuery() {
      console.log("searchQuery")

      //with cache
      const hits = await this.searchWithCache(this.query);
      const mappedHits = hits.map(hit => ({
        id: hit.document.id,
        name: hit.document.name,
        new_isin: hit.document.new_isin,
        symbol: hit.document.symbol,
        isin: hit.document.isin,
        wkn: hit.document.wkn,
        sources: hit.document.sources,
        underlyingtyp: hit.document.underlyingtyp,
      }));
      this.items = mappedHits;

      console.log("searchQuery hits",hits)
      console.log("searchQuery mappedHits",mappedHits)
      console.log("searchQuery items",this.items)
    },
    changeColorMode() {
      this.$colorMode.preference = this.$colorMode.value === "light" ? "dark" : "light";
      this.query = ""; // for search chart display tooltip
      setTimeout(function () {
        const el = document.body;
        if (sessionStorage.getItem("toggleMenu") === "1") {
          el.classList.add("sidebar-collapse");
        } else {
          el.classList.remove("sidebar-collapse");
        }
      }, 200);
    },
    getCountryLanguage() {
      this.countryLanguage = JSON.parse(localStorage.getItem("auth.user"));
      if (this.countryLanguage && this.countryLanguage.countryData != null) {
        return this.countryLanguage.countryData.language.code;
      } else {
        return "de";
      }
    },
    changeLocale(locale) {
      this.isLangSelect = false;
      this.countryLanguage = locale.toUpperCase();
      if (locale === "ar") {
        document.querySelector("html").classList.remove("ltr");
        document.querySelector("html").classList.add("rtl");
      } else {
        document.querySelector("html").classList.remove("rtl");
        document.querySelector("html").classList.add("ltr");
      }
      this.$i18n.setLocale(locale);
      // sessionStorage.setItem("language", locale);
    },
    toggleFullscreen() {
      let targetElement = document.documentElement;
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.isFullscreen = false;
      } else {
        if (targetElement.requestFullscreen) {
          targetElement.requestFullscreen();
        } else if (targetElement.webkitRequestFullscreen) {
          /* Safari */
          targetElement.webkitRequestFullscreen();
        } else if (targetElement.msRequestFullscreen) {
          /* IE11 */
          targetElement.msRequestFullscreen();
        }
        this.isFullscreen = true;
      }
    },
    getResultValue(result, newQuery) {
      //console.log("getResultValue result :",result)
      //console.log("getResultValue result sources:",result.sources)

      //let newResult = result.name + " " + result.symbol + " " + result.wkn;
      //return result.name + " " + result.symbol + " " + result.wkn;

      //return `${result.name || ''} ${result.symbol || ''} ${result.wkn || ''} ${result.sources || ''} ${result.isin || ''}`;

      //let newResult = `${result.name || ''} ${result.symbol || ''} ${result.wkn || ''} ${result.sources || ''} ${result.isin || ''}`
      let newResult = `${result.name} (ISIN: ${result.isin ? result.isin : '-'} / WKN: ${result.wkn ? result.wkn : '-'} / Symbol: ${ result.symbol ? result.symbol : '-' })`;
      //console.log("getResultValue result newResult:",newResult)
      return newResult;


      //return result.name + " d0d - " + result.wkn;

      /*if (result.sources === 1) {
        if (document.getElementById("no-result")) {
          document.getElementById("no-result").remove();
        }
        return result.symbol;
      }

      if (result.sources === 2 && result.name) {
        if (document.getElementById("no-result")) {
          document.getElementById("no-result").remove();
        }
        return result.name;
      }

      if (result.sources === 3) {
        if (!document.getElementsByClassName("vbst-item").length) {

          let appendHtml = "<a tabindex='0' href='javascript:void(0);' class='vbst-item list-group-item list-group-item-action text-muted' id='no-result' role='option' aria-selected='false'><div class='sr-only'>No result found.</div><div aria-hidden='true'><span>No result found.</span></div></a>";

          document.querySelector(".vbt-autcomplete-list")
            .insertAdjacentHTML("beforeend", appendHtml);
        }
      }

      return result.symbol;*/

    },
    getCertoxdataPrice(el) {
      this.$axios.get("get-item-price?code=" + el.symbol).then((response) => {
        el.price = response.data.ask;
      });
    },
    getTwelvedataPrice(el) {
      this.$axios.get("price?symbol=" + el.symbol + "&dp=2").then((response) => {
        if (response.data.status !== "error") {
          el.price = response.data.price;
        }
      });
    },
    realTimeTwelvedata(el) {
      // Create WebSocket connection.
      const socket = new WebSocket(
        "wss://ws.twelvedata.com/v1/quotes/price?apikey=" +
          this.$config.TWELVE_DATA_API_KEY
      );

      // Connection opened
      socket.addEventListener("open", function (event) {
        socket.send(
          JSON.stringify({
            action: "subscribe",
            params: {
              symbols: el.symbol,
            },
          })
        );
      });

      // Listen for messages
      socket.addEventListener("message", function (event) {
        let data = JSON.parse(event.data);

        if (data.event === "price") {
          el.price = data.price;
        }
      });
    },
    realTimeCertoxData(element) {
      const sockJs = new SockJS("https://quotes.xeuq.net/quotesocket");

      sockJs.onopen = () => {
        console.log("sockJs connection open");
        sockJs.send("add;" + element.symbol + ".FFMST");
      };

      sockJs.onmessage = (event) => {
        console.log(event.data);
        element.price = event.data.split(";")[3];
      };

      sockJs.onclose = () => {
        console.log("sockJs connection close");
      };
    },

    async handleSubmit(result) {
      console.log("handleSubmit result:",result)
      if (result.sources === 2) {

        let uIsin, isin, typ, wkn;
        if (result.isin != "") {
          /*await this.$axios.get("eodisin?isin=" + result.isin).then((response) => {
            console.log("eodisin response:",response)
            var isinData = response.data[0];
            if (isinData.Code) {
              var symbole = isinData.Code;
            } else {
              var symbole = isinData.symbole;
            }
            if (isinData.Exchange) {
              var exchange = isinData.Exchange;
            } else {
              var exchange = isinData.exchange;
            }
            this.$router.push(
              this.localeLocation(
                "/" + symbole + "?symbol=" + symbole + "&exchange=" + exchange
              )
            );
          });*/

          let symbol = result.symbol;
          let exchange = this.exchange;
          /*this.$router.push(this.localeLocation("/" + symbol
                  + "?symbol=" + symbol + "&exchange=" + exchange));*/

          let type = result.underlyingtyp.toLowerCase();
          let isin = result.isin;
          let nameISIN = result.name.replace(/\s+/g, '-') + '-'+isin;
          if(type==="stock"){
            type = "stocks";
            await this.$router.push("/" + type + "/" + nameISIN);
          }else{
            await this.$router.push(this.localeLocation("/" + symbol + "?symbol=" + symbol + "&exchange=" + exchange));
          }
        }
      } else {

        let uIsin, isin, typ, wkn;
        //let code = result.symbol;
        let code = result.wkn;
        
        console.log("handleSubmit else:",result)

        await this.$axios.get("get-item-data?code="+code).then((response) => {
          uIsin = response.data.u_isin;
          isin = response.data.isin;
          typ = response.data.typ;
          wkn = response.data.wkn;
        });

        if (uIsin && isin) {
          await this.$axios
            .post("add-certox-mapping-data", {
              isin: isin,
              u_isin: uIsin,
            })
            .then((response) => {
              if (!response.data.success) {
                return false;
              }
              if (response.data.success) {
                let name = response.data.data[0].Name.split(" ").join("+");
                let assetsClassName = response.data.assetsClass;
                // this.$router.push(
                //   "/" + this.$i18n.t("search_name_typ." + typ) + "/" + name + "/" + wkn
                // );
                if (assetsClassName != "") {
                  this.$router.push(
                    "/" +
                      assetsClassName +
                      "/" +
                      name +
                      "/search_name_typ." +
                      typ +
                      "/" +
                      wkn
                  );
                } else {
                  this.$router.push("/" + name + "/search_name_typ." + typ + "/" + wkn);
                }
              }
            })
            .catch((error) => {
              return false;
            });
        }
      }
    },
    getChartOptions(data, header) {
      
      data = [23, 45, 66, 12, 33]; //static
      //console.log("Navbar getChartOptions data:",data);
      //console.log("Navbar getChartOptions header:",header);

      if (this.$colorMode.value === "light") {
        return {
          series: [
            {
              data,
              pointStart: 1,
            },
          ],

          tooltip: {
            enabled: true,
            animation: true,
            borderRadius: 3,
            dateTimeLabelFormats: {
              millisecond: "%A, %b %e, %H:%M:%S.%L",
              second: "%A, %b %e, %H:%M:%S",
              minute: "%A, %b %e, %H:%M",
              hour: "%A, %b %e, %H:%M",
              day: "%A, %b %e, %Y",
              week: "Week from %A, %b %e, %Y",
              month: "%B %Y",
              year: "%Y",
            },
            footerFormat: "",
            padding: 8,
            snap: 10,
            backgroundColor: "rgba(247,247,247,0.85)",
            borderWidth: 1,
            shadow: true,
            style: {
              color: "#333333",
              cursor: "default",
              fontSize: "12px",
              whiteSpace: "nowrap",
            },
            headerFormat: `<span style="font-sze:10px">${header}:</span><br/>`,
            pointFormat: "<b>{point.y}</b>",
          },
        };
      } else {
        return {
          series: [
            {
              data,
              pointStart: 1,
            },
          ],

          tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            style: {
              color: "#F0F0F0",
            },
            headerFormat: `<span style="font-sze:10px">${header}:</span><br/>`,
            pointFormat: "<b>{point.y}</b>",
          },
        };
      }
    },
    addToWatchlist() {
      console.log("Add to watchlist");
    },
    clearUserInfo() {
      this.$store.dispatch("authModule/logout").then(() => {
        // this.$router.push({name: 'portlets'});
        window.location = "/login";
      });
    },
    logout() {
      this.$axios.post("/logout").finally(() => this.clearUserInfo());
    },
    actionToggelMenu() {
      this.toggleMenuFlag = sessionStorage.getItem("toggleMenu");
      const el = document.body;
      if (this.toggleMenuFlag === "1") {
        sessionStorage.setItem("toggleMenu", 0);
        el.classList.remove("sidebar-collapse");
      } else {
        el.classList.add("sidebar-collapse");
        sessionStorage.setItem("toggleMenu", 1);
      }
    },
  },
  mounted() {
    if (process.client) {
      this.userData = localStorage.getItem("auth.user");
    }
    // this.countryLanguage = JSON.parse(localStorage.getItem("auth.user"));
    // console.log("User Data", this.countryLanguage.countryData.language.code);
    // this.$i18n.locales.push({
    //   code: "da",
    //   dir: "ltr",
    //   file: "da.json",
    //   name: "Data",
    // });
    this.countryLanguage = this.getCountryLanguage();
    this.$i18n.defaultLocale = this.countryLanguage.toLowerCase();
    this.changeLocale(this.$i18n.defaultLocale);

    //Search

    /*const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: 'WylcECnuurQXref5CBv0QjJX1ICb0wDvoPeccMfNIp4nB4pP',
        nodes: [
          {
            host: '65.109.8.121',
            port: '8108',
            protocol: 'http', // or 'https' if using SSL
          },
        ],
      },
      additionalSearchParameters: {
        query_by: 'name,new_isin,symbol',
      },
    });

    this.search = instantsearch({
      searchClient: typesenseInstantsearchAdapter.searchClient,
      indexName: 'securedz_stat_index', // Change this to your actual index name
    });

    this.search.addWidgets([
      instantsearch.widgets.searchBox({
        container: '.form-control', // Provide the CSS selector of the input
      }),
      instantsearch.widgets.hits({
        container: '.vue-typeahead-bootstrap',
        templates: {
          item(hit) {
            return `<div>${hit.name}</div>`;
          },
        },
      }),
    ]);
    this.search.start();*/
  },
};
