
export default {
  mounted() {
    /*const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6197390711712332"
    );
    script.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(script);*/
  },
};
